<template>
	<div :class="{showToolbar: focused || modelValue || fullscreen, mobile: mobile, hasToolbar: hasToolbar(), fullscreen: fullscreen}" class="contentEditableContainer" @click="handleClickInContainer()">
		<button class="bl-icon-button exitFullscreen" v-if="fullscreen" @click="toggleFullScreen()">close_fullscreen</button>
		<div contenteditable="true" class="contenteditable" ref="contentEditable" @keyup="saveSelection()" @input="setModel()" @focus="focus()" @blur="blur()" @mousedown="startSelectionChange()"></div>
		<button class="bl-icon-button openToolbarButton" type="button" tabindex="-1" v-if="compact && !mobile && !toolbarOpened" @click="toolbarOpened = true; $refs.contentEditable.focus();">format_color_text</button>
		<div class="toolbar bl-light-scroll" v-if="hasToolbar()">
			<InterfaceWysiwygElements ref="elements" :command="command" :fullscreen="fullscreen" :image="image" :link="link" :from="0" />

			<BlMenu v-if="showMoreMenu">
				<template #hook>
					<button class="bl-icon-button" tabindex="-1" type="button">more_vert</button>
				</template>
				<div class="toolbarMenu">
					<InterfaceWysiwygElements :command="command" :fullscreen="fullscreen" :image="image" :link="link" :from="showMoreMenuFrom" />
				</div>
			</BlMenu>
		</div>
	</div>
</template>

<script>
import { Dialog, Variables, EventEmitter } from 'InterfaceBundle'
import InterfaceWysiwygElements from './InterfaceWysiwygElements'

export default {
	name: 'BlWysiwyg',
	props: ['modelValue', 'compact'],
	emits: ['update:modelValue', 'change', 'blur', 'focus'],
	components: {
		InterfaceWysiwygElements
	},
	data() {
		return {
			toolbarOpened: false,
			fullscreen: false,
			mobile: Variables.mobile,
			lastValue: '',
			focused: false,
			lastSelection: null,
			commandStack: [],
			showMoreMenu: false,
			showMoreMenuOpened: false,
			showMoreMenuFrom: 0,
			endSelectionChangeCb: () => this.endSelectionChange()
		}
	},
	watch: {
		modelValue(val) {
			if(val !== this.lastValue) this.setContent()
		}
	},
	mounted() {
		this.setContent()
		this.resizeObserver = new ResizeObserver(() => this.setMenuLength())
		this.resizeObserver.observe(this.$el)
	},
	unmounted() {
		this.resizeObserver.disconnect()
	},
	methods: {
		setContent() {
			this.$refs.contentEditable.innerHTML = this.modelValue ? this.modelValue : ''
			this.lastValue = this.modelValue ? this.modelValue : ''
		},
		setModel() {
			this.saveSelection()
			let val = this.$refs.contentEditable.innerHTML
			this.lastValue = val
			if(val == '<div><br></div>') val = ''
			this.$emit('update:modelValue', val)
			this.$emit('change')
		},
		saveSelection() {
			const ret = new EventEmitter()
			this.$forceUpdate()
			setTimeout(() => {
				this.lastSelection = window.getSelection().getRangeAt(0)
				this.commandStack = []
				ret.emit()
			})
			this.forceToolbarUpdate()
			return ret
		},
		command(command, arg = null, restoreSelection = false) {
			if(command == 'fullscreen') {
				this.toggleFullScreen()
				return
			}
			if((this.lastSelection && this.lastSelection.toString().length == 0) || restoreSelection) {
				let selection = window.getSelection()
				selection.removeAllRanges()
				selection.addRange(this.lastSelection)
			}
			this.lastSelection = window.getSelection().getRangeAt(0)
			if(document.queryCommandState(command)) this.commandStack = []
			this.commandStack.push([command, arg])
			for(let item of this.commandStack) document.execCommand(item[0], false, item[1])
			this.forceToolbarUpdate()
		},
		forceToolbarUpdate() {
			if(this.$refs.elements) this.$refs.elements.$forceUpdate()
		},
		link() {
			this.saveSelection().once(() => {
				let defaultLink = ''
				const lastSel = this.lastSelection.toString()
				if(lastSel.substr(0, 4) == 'www.' || lastSel.substr(0, 5) == 'http:' || lastSel.substr(0, 6) == 'https:') defaultLink = lastSel
				Dialog.prompt({
					title: this.$t('interface.wysiwyg.linkDialog.title'),
					promptLabel: this.$t('interface.wysiwyg.linkDialog.label'),
					promptValue: defaultLink,
					accept: this.$t('interface.wysiwyg.linkDialog.accept')
				}).then(resp => {
					if(lastSel.substr(0, 7) != 'http://' && lastSel.substr(0, 8) != 'https://') resp = 'http://' + resp
					this.command('createLink', resp, true)
					document.getSelection().anchorNode.parentElement.target = '_blank'
					this.setModel()
				})
			})
		},
		async image() {
			let fileHandle = await window.showOpenFilePicker({
				types: [{
					description: 'Images',
					accept: {'image/*': ['.png', '.gif', '.jpeg', '.jpg']}
				}],
				excludeAcceptAllOption: true,
				multiple: false
			})

			let fileContent =  await fileHandle[0].getFile()
			let encode =  await new Promise(resolve => {
				let reader = new FileReader()
				reader.onloadend = () => resolve(reader.result)
				reader.readAsDataURL(fileContent)
			})

			this.command('insertHTML', '<img src="' + encode + '">', true)
		},
		focus() {
			this.$refs.contentEditable.focus()
			this.focused = true
			this.$emit('focus')
			this.forceToolbarUpdate()
		},
		blur() {
			this.focused = false
			this.$emit('blur')
			this.forceToolbarUpdate()
		},
		hasToolbar() {
			return !this.mobile && (!this.compact || this.toolbarOpened)
		},
		toggleFullScreen() {
			this.fullscreen = !this.fullscreen
			if(this.fullscreen) {
				this.parentElement = this.$el.parentElement
				document.body.prepend(this.$el)
			}
			else this.parentElement.prepend(this.$el)
		},
		setMenuLength() {
			let hideItemsFrom = null
			let maxWidth = this.$el.getBoundingClientRect().right - 50
			let items = this.$el.querySelectorAll('.toolbarItem')
			for(let item of items) item.style.display = null
			this.$nextTick(() => {
				let itemIndex = 1
				for(let item of items) {
					if(item.getBoundingClientRect().right >= maxWidth) {
						hideItemsFrom = itemIndex
						break
					}
					itemIndex++
				}
				if(hideItemsFrom && hideItemsFrom != items.length) {
					itemIndex = 1
					for(let item of items) {
						if(itemIndex >= hideItemsFrom) {
							item.style.display = 'none'
						}
						itemIndex++
					}
					this.showMoreMenu = true
					this.showMoreMenuFrom = hideItemsFrom - 1
				}
				else {
					this.showMoreMenu = false
					this.showMoreMenuOpened = false
				}
			})
		},
		handleClickInContainer() {
			if(!this.focused) this.$refs.contentEditable.focus()
		},
		startSelectionChange() {
			document.addEventListener('mouseup', this.endSelectionChangeCb)
		},
		endSelectionChange() {
			this.saveSelection()
			document.removeEventListener('mouseup', this.endSelectionChangeCb)
		}
	}
}
</script>

<style scoped lang="scss">
	.toolbar {
		position: relative;
		display: flex;
		opacity: 0;
		pointer-events: none;
		transition: opacity .1s;
		margin: -35px 0 4px 4px;
		width: calc(100% - 16px);
		background-color: var(--bl-surface);
		padding: 2px 4px 2px 4px;
		border-radius: var(--bl-border-radius);
		background-color: var(--bl-background);
		overflow: hidden;
		
		.bl-icon-button {
			margin: 0 -1px;
			font-size: 22px;
			padding: 4px;
			color: var(--bl-legend);
			width: 33px;
			min-width: 33px;
			height: 33px;
			line-height: 27px;
			overflow: hidden;
			position: relative;
		}
	}

	.toolbarMenu {
		display: flex;
		flex-wrap: wrap;
	}

	div[contenteditable].contenteditable {
		padding: 8px 8px 8px 8px;
		flex: 1;
		outline: none;
		overflow: auto;
	}

	.hasToolbar div[contenteditable].contenteditable {
		padding-bottom: 0;
		margin-bottom: 40px;
	}

	.mobile div[contenteditable].contenteditable {
		padding-bottom: 8px;
		min-height: 49px;
	}

	.showToolbar .toolbar, .contentEditableContainer:focus-within .toolbar {
		pointer-events: auto;
		opacity: 1;
	}

	.contentEditableContainer {
		display: flex;
		flex-direction: column;
	}

	.contentEditableContainer.fullscreen {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1000;
		background: var(--bl-surface);

		button.exitFullscreen {
			right: 0;
			position: absolute;
		}
	}

	.contentEditableContainer:not(.hasToolbar) {
		flex-direction: row;
		align-items: flex-start;
	}

	.contentEditableContainer:not(.showToolbar) {
		cursor: text;
	}

	.openToolbarButton {
		color: var(--bl-legend);
		font-size: 16px;
		padding: 0;
		float: right;
		margin-top: 8px;
		margin-right: 8px;
	}
</style>