<template>
	<div ref="dialogsContainer">
		<div class="bl-dialog-backdrop" v-for="item in stack" :key="item.id" :style="{'z-index': 1000 + item.id}" @mousedown.self="item.data.required ? requiredAnimate() : close(false)">
			<dialog class="bl-dialog-container" open :class="getContainerClass(item)">
				<button class="bl-icon-button" v-if="item.data.closeButton" @click="close(false)">close</button>
				<h1 v-if="item.data.title || item.data.title == ''"><component :is="{template: item.data.title, data: titleData}" /></h1>
				<p v-if="item.data.content" v-html="item.data.content"></p>
				<div v-bl-input v-if="item.data.promptType" :class="{errored: item.data.required && !item.data.promptValue}">
					<label v-if="item.data.promptLabel">{{ item.data.promptLabel }}</label>
					<input :type="item.data.promptType" v-model="item.data.promptValue" :required="item.data.required"  v-on:keyup.enter="close(true)" />
				</div>
				<div v-if="item.data.accept || item.data.cancel" class="actionsContainer">
					<BlButton :classlist="{outlined: true}" v-if="item.data.cancel" :label="item.data.cancel" @click="close(false)" />
					<BlButton v-if="item.data.accept" :label="item.data.accept" @click="close(true)" />
				</div>
				<component v-if="item.data.component" :is="item.data.component" v-bind="item.data.componentProps" />
			</dialog>
		</div>
	</div>
</template>

<script>

import { Dialog } from 'InterfaceBundle'

export default {
	name: 'BlDialogContainer',
	data() {
		return {
			stack: [],
			requiredAnimation: false,
			inAnimation: {},
			titleData: () => {
				return {services: {routeParams: {}}}
			}
		}
	},
	created() {
		Dialog.change.subscribe(() => {
			this.stack = []
			for(let item of Dialog.stack) this.stack.push(item)

			if(this.stack.length) {
				this.$nextTick(() => {
					let top = this.stack[this.stack.length - 1]
					if(top.data.promptType) {
						this.$el.querySelector('input').focus()
						this.$el.querySelector('input').select()
					}
					else if(top.data.accept) {
						this.$el.querySelector('button:not(.outlined):not(.bl-icon-button)').focus()
					}
				})
			}
		})
	},
	methods: {
		close(valid) {
			let top = this.stack[this.stack.length - 1]
			let valueValid = true
			//Check required value
			if(valid && top.data.promptType) {
				if(top.data.required && !top.data.promptValue && top.data.promptValue !== 0) {
					valueValid = false
					this.$el.querySelector('input').focus()
					this.requiredAnimate()
				}
			}
			if(valueValid) Dialog.close(valid)
		},
		requiredAnimate() {
			this.requiredAnimation = true
			setTimeout(() => this.requiredAnimation = false, 700)
		},
		getContainerClass(item) {
			let ret = {}
			if(typeof item.data.class === 'string') for(let className of item.data.class.split(' ')) ret[className] = true
			else if(Array.isArray(item.data.class)) for(let className of item.data.class) ret[className] = true
			ret.required = this.requiredAnimation
			ret.in = item.inAnimation
			ret.fullScreen = item.data.fullScreen
			return ret
		}
	}
}
</script>

<style scoped lang="scss">
	.bl-icon-button {
		float: right;
		margin-top: -9px;
	}

	@media only screen and (max-width: 400px) {
		.bl-dialog-container:not(.fullScreen) {
			min-width: calc(100% - 60px) !important;
		}
	}

	.bl-dialog-container {
		overflow-x: auto;
		max-height: calc(100% - 60px);
		position: absolute;
		min-width: 100px;
		min-height: 100px;
		background-color: var(--bl-surface);
		border-radius: var(--bl-border-radius);
		min-width: 330px;
		max-width: calc(100% - 60px);
		border: 0;
		padding: 25px;

		p {
			color: var(--bl-legend);
			margin: 0;
			padding: 0;
		}
	}

	.bl-dialog-container :deep h1 {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-family: Product sans;
		font-size: 21px;
		margin: 0 0 20px 0;
		min-height: 25px;
	}


	.bl-dialog-container :deep .actionsContainer {
		text-align: right;
		padding-top: 30px;

		button {
			margin-left: 10px;
		}
	}

	.bl-dialog-container.fullScreen {
		max-height: calc(100% - 50px);
		width: calc(100% - 50px);
		max-width: calc(100% - 50px);
		height: calc(100% - 50px);
		border-radius: 0;
	}

	.bl-dialog-backdrop {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .66);
		z-index: 10;
		animation: bl-dialog-backdrop-animate-in .2s;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@keyframes bl-dialog-backdrop-animate-in {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	.bl-dialog-container.in {
		animation: bl-dialog-container-animate-in .2s;
	}

	@keyframes bl-dialog-container-animate-in {
		0% {
			transform: scale(.7);
		}
		100% {
			transform: scale(1);
		}
	}

	.bl-dialog-container.required {
		animation: shake 0.7s cubic-bezier(.36,.07,.19,.97) both;
		transform: translate3d(0, 0, 0);
	}

	@keyframes shake {
		10%, 90% {
			transform: translate3d(-1px, 0, 0);
		}

		20%, 80% {
			transform: translate3d(2px, 0, 0);
		}

		30%, 50%, 70% {
			transform: translate3d(-4px, 0, 0);
		}

		40%, 60% {
			transform: translate3d(4px, 0, 0);
		}
	}

	div.bl-input {
		margin-top: 7px;
		transform: scale(1.2);
		width: calc(100% - 72px);
		margin-left: 26px;
	}
</style>