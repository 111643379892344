function blShowDirectiveUpdate(el, bindings) {
	let grids = []
	const gridValidators = ['s', 'm', 'l', 'xl', 'all']
	if(bindings.modifiers) {
		for(let key in bindings.modifiers) {
			if(!gridValidators.includes(key)) throw new Error('Invalid grid value "' + key + '", should be one of : [' + gridValidators.join(', ') + ']')
			if(bindings.modifiers[key]) grids.push(key)
		}
	}
	if(!grids.length) grids = ['all']

	//Remove previous classes
	el.classList.forEach(className => {
		for(let grid of grids) {
			if(className.substr(0, 8 + grid.length) == 'bl-hide-' + grid || className.substr(0, 8 + grid.length) == 'bl-show-' + grid) el.classList.remove(className)
		}
	})

	for(let grid of grids) el.classList.add('bl-' + (bindings.value === false ? 'hide' : 'show') + '-' + grid)
}

export default {
	mounted(el, bindings) {
		blShowDirectiveUpdate(el, bindings)
	},
	updated(el, bindings) {
		blShowDirectiveUpdate(el, bindings)
	}
}